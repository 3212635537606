.ant-table-wrapper {
  flex: 1;
  height: 100%;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ant-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ant-table-body {
  flex: 1;
}

.ant-table-content {
  height: 100%;
}

.ant-pagination {
  flex: none !important;
}

.ant-table-cell {
  padding: 1.0625rem 1rem !important;
  text-align: left !important;
}

.ant-table-column-title {
  text-align: left;
}

.ant-select-item-option-active,
.ant-select-item-option-selected {
  color: #4d7cee !important;
  background-color: #edf2fd !important;
}
