.ant-tabs-nav-list {
  display: flex;
  width: 100% !important;
}

.ant-tabs-tab {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-tabs-content {
  width: 100%;
  height: 100%;
  display: flex;
}

.ant-tabs-tabpane {
  flex: 1;
}

.ant-table-wrapper {
  flex: 1;
}

.ant-spin-nested-loading {
  height: 100%;
}

.ant-spin-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ant-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ant-table-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ant-table-body {
  flex: 1;
}

.ant-pagination {
  flex: none !important;
}

.ant-table-content table tr .ant-table-cell {
  padding: 0.5rem 0.3125rem;
  text-align: center;
}

.ant-table-column-title {
  text-align: center;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #f4f7fe;
}

.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  color: #0b1121;
  font-weight: 900;
}

.ant-message-notice-error .ant-message-notice-content {
  background-color: #fef0f0 !important;
  color: #ff4e4e;
}

.ant-message-notice-success .ant-message-notice-content {
  background-color: #ebf9ee !important;
  color: #0ed382;
}

.ant-modal-body {
  flex: 1;
  height: 100%;
}
