@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #edf2fd !important;
  color: #4d7cee !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.myTable .ant-spin-nested-loading .ant-spin-container .ant-table {
  height: 37.9rem;
}
.myTable .ant-spin-nested-loading .ant-spin-container .ant-pagination {
  margin-top: 2.5rem;
}

.pronounce .ant-table {
  font-size: 0.8125rem;
}

.pronounce .ant-table-column-sorters {
  padding: 0;
}

.pronounce .ant-table-column-sorters .ant-table-column-title {
  text-align: start;
}

.pronounce .ant-table-content {
  font-size: 0.75rem;
}

.pronounce .ant-table-cell {
  padding: 0rem 1rem;
  text-align: start;
  height: 3.5rem;
  color: #0b1121;
  font-size: 0.875rem;
}

.pronounce .ant-select-selector {
  border-radius: 0;
}

.pronounce .ant-select-selection-item {
  font-weight: 500;
  padding-right: 0.1875rem !important;
}

.pronounce .ant-table-body::-webkit-scrollbar {
  width: 0;
}

.avatarLayout :nth-child(3n-1) {
  margin: 0 3.5%;
}

.avatarAndBackground .ant-pagination-prev,
.avatarAndBackground .ant-pagination-next,
.avatarAndBackground .ant-pagination-item {
  border: 0.0625rem #e9edf2 solid;
  margin: 0 0.3rem !important;
}

.avatarAndBackground .ant-pagination-item {
  margin: 0;
  margin-right: 0.5rem;
}

.avatarAndBackground .ant-pagination-item-active {
  background: #475aec;
}

.avatarAndBackground .ant-pagination-item-active a,
.avatarAndBackground .ant-pagination-item-active a:hover {
  color: #fff;
}

.numberSelect .ant-select-selector,
.videoSettingsSelect .ant-select-selector {
  color: #4d7cee !important;
  border: none !important;
  margin-right: 0.3rem;
  background-color: #dbe5fc !important;
}

.generateVideoSelect .ant-select-selector,
.productionSelect .ant-select-selector {
  color: #4d7cee !important;
  border-color: #4d7cee !important;
  margin-right: 0.3rem;
}

.numberSelect,
.productionSelect,
.generateVideoSelect {
  text-align: center;
}

.productionSelect .ant-select-arrow {
  right: 1.8rem !important;
}

.generateVideoSelect .ant-select-arrow {
  right: 2.1rem !important;
}

.numberSelect .ant-select-arrow {
  right: 。5rem !important;
}

.digitalSelectorTabs .ant-tabs-nav::before {
  border: 0;
}

.digitalSelectorTabs .ant-tabs-nav-wrap {
  border-radius: 0.5rem !important;
}

.digitalSelectorTabs .ant-tabs-content.ant-tabs-content-top {
  display: block;
}

.digitalSelectorTabs .ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}

.digitalSelectorTabs .ant-tabs-tab {
  border-radius: 0 !important;
  border: 0 !important;
  color: #94b0f5;
  background: #f4f7fe !important;
  height: 2.625rem;
}

.digitalSelectorTabs .ant-tabs-tab-active {
  background: #4d7cee !important;
}

.digitalSelectorTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff;
}

.soundSlider .ant-slider-tooltip {
  inset: auto auto 0px -22.5469px !important;
}

.soundSlider.ant-slider .ant-slider-handle::after {
  box-shadow: none !important;
}

.soundSlider .ant-slider-tooltip .ant-tooltip-inner {
  background: transparent;
  color: #8c8e9f;
  box-shadow: none;
  padding-bottom: 0;
  font-size: 0.75rem;
}

.soundSlider .ant-slider-tooltip .ant-tooltip-arrow::after,
.soundSlider .ant-slider-tooltip .ant-tooltip-arrow::before {
  display: none;
}

.broadcastText .w-e-text-container {
  background-color: #f2f2f2;
  border-radius: 0.5rem;
  overflow: hidden;
}

.ant-modal-wrap::-webkit-scrollbar {
  width: 0;
  overflow-y: auto;
}

.myCreateModal .ant-modal-content {
  background: #f4f7fe;
  padding: 0;
  height: 90vh;
}

.myCreateModal .ant-modal-close {
  color: #323232;
}

.myCreateInput .ant-input {
  margin-left: 0.75rem;
}

.myCreateTextArea .ant-input {
  padding: 1rem;
}

.myCreateTextArea .ant-input-suffix .ant-input-clear-icon .anticon {
  position: absolute;
  right: 0.7rem;
  top: -0.4rem;
}

.ant-card-cover video {
  object-fit: cover;
}

.homePagination .ant-pagination-item-link {
  border: 0.0625rem solid #ced2d8 !important;
}

.homeDropdown .ant-dropdown-menu-item-active {
  background: #edf2fd !important;
  color: #4d7cee !important;
  border-radius: 0.5rem !important;
}

.homeDropdown .ant-dropdown-menu-item {
  margin: 0.1875rem !important;
}

.homeDropdown .ant-dropdown-menu {
  border-radius: 0.75rem !important;
}

.item-color .ant-menu-item {
  background-color: white;
}

.item-color .ant-menu-item-selected {
  background-color: #f4f7fe;
}

.item-color .ant-menu {
  background-color: white !important;
}

.css-dev-only-do-not-override-1q917lb a {
  color: inherit !important;
}

input.password {
  font-family: text-security-disc;
  -webkit-text-security: disc;
}

.recyclingPagination {
  margin-top: 0.5rem !important;
  margin-right: 1rem !important;
}

.recycling .ant-table-cell {
  background-color: #f4f7fe !important;
}

.broadcastQuill p {
  display: inline;
}

.broadcastQuill .ql-toolbar.ql-snow {
  height: 0;
  border-bottom: none;
  padding: 0;
}
